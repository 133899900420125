import { display } from "@mui/system";
import React, { useState, useEffect } from "react";
import "./FormNew.css";
import emailjs from "@emailjs/browser";
import { colors } from "@material-ui/core";

export default function Form() {
  const [modal, setModal] = useState(false);
  const [name, setName] = useState("");
  const [number, setnumber] = useState(0);
  const [alert, setAlert] = useState("");
  console.log(name);

  const handleSubmit = () => {
    if (!name || !number) {
      setAlert("Please fill in all required fields.");
      return;
    }

    const templateParams = {
      name: `${name}`,
      number: `${number}`,
    };

    emailjs
      .send(
        "service_65a2cik",
        "template_fq2q6o5",
        templateParams,
        "irB56rRK5zm2576zv"
      )
      .then(
        setAlert(true),
        (response) => {
          console.log("EMAIL SUCCESS!", response.status, response.text);
        },
        (err) => {
          console.log("EMAIL FAILED...", err);
        }
      )
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };

  console.log("Form Running");
  console.log("model", modal);

    useEffect(() => {
      const timer = setTimeout(() => {
  setModal(true)
        console.log('This will run after 5 second!')
      }, 10000);
      return () => clearTimeout(timer);
    }, []);

  const toggleModal = () => {
    setModal(!modal);
  };

  if (modal) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }



  return (
    <>
      {modal && (
        <div className="inset  ">
          <div className="overlay ">
          <div className="modal-content ">
            <h2 className="enquire">ENQUIRE NOW</h2>
            <p className="wanttoknow">
              Want to know more? We are here to help!
            </p>
            <div className="from">
              <h6 className="name">Name:*</h6>
              <div className="inputs">
                <input
                  className="nameinput"
                  type="text"
                  placeholder=" Enter Name"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>
            <div style={{ display: "flex", marginTop: "10px" }}>
              <h6 className="phone">Phone no*</h6>
              <div style={{ borderBottom: "2px solid gray" }}>
                <input
                  style={{ border: "none ", outline: "none " }}
                  type="text"
                  placeholder="Enter Phone Number"
                  onChange={(e) => setnumber(e.target.value)}
                />
              </div>
            </div>
            <button className="submit" onClick={handleSubmit}>
              Submit
            </button>
            {alert && (
              <p style={{ font: "2px", marginTop: "10px" }}>
                Thank You! Your Form has been Submitted successfully
              </p>
            )}
            <img
              className="close-modal"
              onClick={toggleModal}
              src="https://icons.veryicon.com/png/o/miscellaneous/medium-thin-linear-icon/cross-23.png"
              alt=""
            />
          </div>
          </div>
        </div>
      )} 
    </>
  );
}
