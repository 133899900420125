import React from "react";

const Party = () => {
  return (
    <div>
      <h2>PARTY MAKEUP</h2>
      <div className="Box">
        <div className="One">
          <img src="./Images/Rectangle 113.png"></img>
        </div>
        <div className="Two">
          <p>
            Not sure of what makeup you should wear for your friends Mehndi
            tomorrow? we are happy to help. Sohni Juneja Party makeover will
            definitely make you the center of attraction of the Party. If you
            want amazing makeup styles, then you are at the right place. As
            trends change rapidly with the changing time. Professional makeup
            artist, Sohni Juneja provides makeup with the best products that
            suit your skin perfectly and cover all the imperfections of the
            skin.
          </p>
          <p>
          Latest makeup techniques are used in providing the best ever makeup by Sohni Juneja. She has exclusive beauty product packs to offer her clients so that their skin will look fresh and attractive. So, if you are going to someone’s party but seems to be confused about the perfect look you should have for the party, then make an appointment with Sohni Juneja and be prepared for the wonderful amazing look you wish to achieve. You can fix your Makeup appointment at our saloon or get the Best Makeup artist in Dehradun and Delhi at home.

          </p>
          <p>
          Get a sensational look and let everyone adore your beauty with Delhi's best makeup artist at affordable rates. Call us or book your appointment with Best Party & Event Makeup Artist in Delhi.

          </p>
         

          <article>View Gallery</article>
        </div>
      </div>
    </div>
  );
};

export default Party;
