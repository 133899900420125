import React from 'react'
import Confirm from "./components/Confirmation/index"

function Checkout() {
  return (
    <div>
      <Confirm/>
    </div>
  )
}

export default Checkout