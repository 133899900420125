import React from 'react'

const Offer = () => {
  return (
    <div>
      <h2>OFFERS
</h2>
      <div className="Box">
        <div className="One">
          <img src="./Images/Rectangle 111.png"></img>
        </div>
        <div className="Two">
          <p>
          With every 3 makeup looks,  get a free day of makeup. For every 4 hairstyles, you get a hairstyle free.Sohni Juneja makes sure her client’s makeup makes them all ready for the party. Girls or women, everybody wants to be the center of attraction at a party or on a special day, then try out Sohni Juneja’s makeup service. Here, you will get a sensational look as she will give you the makeup look that suits you.
          </p>
          <p>
          
Get a sensational look and let everyone adore your beauty with the best makeup artist in Dehradun and Delhi at affordable rates. Call us or book your appointment with Top Makeup Artist in Delhi.

          </p>
        

          <article>View Gallery</article>
        </div>
      </div>
    </div>
  )
}

export default Offer